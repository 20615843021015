import { render, staticRenderFns } from "./ProductionUnits.vue?vue&type=template&id=91d24a90&scoped=true"
import script from "./ProductionUnits.vue?vue&type=script&lang=js"
export * from "./ProductionUnits.vue?vue&type=script&lang=js"
import style0 from "./ProductionUnits.vue?vue&type=style&index=0&id=91d24a90&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91d24a90",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/test.webapp/releases/release_2024-09-09-142012/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('91d24a90')) {
      api.createRecord('91d24a90', component.options)
    } else {
      api.reload('91d24a90', component.options)
    }
    module.hot.accept("./ProductionUnits.vue?vue&type=template&id=91d24a90&scoped=true", function () {
      api.rerender('91d24a90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/components/ProductionUnits.vue"
export default component.exports