import { render, staticRenderFns } from "./ReviewChangeProposalsModal.vue?vue&type=template&id=1b123422&scoped=true"
import script from "./ReviewChangeProposalsModal.vue?vue&type=script&lang=js"
export * from "./ReviewChangeProposalsModal.vue?vue&type=script&lang=js"
import style0 from "./ReviewChangeProposalsModal.vue?vue&type=style&index=0&id=1b123422&lang=scss"
import style1 from "./ReviewChangeProposalsModal.vue?vue&type=style&index=1&id=1b123422&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b123422",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/test.webapp/releases/release_2024-09-09-142012/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b123422')) {
      api.createRecord('1b123422', component.options)
    } else {
      api.reload('1b123422', component.options)
    }
    module.hot.accept("./ReviewChangeProposalsModal.vue?vue&type=template&id=1b123422&scoped=true", function () {
      api.rerender('1b123422', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/components/ReviewChangeProposalsModal.vue"
export default component.exports